
let currentIndex = 0;
const galleryItems = jQuery(".block-social-media .gallery-item");
const modal = jQuery(".block-social-media#imageModal");
const popupImage = jQuery(".block-social-media #popupImage");
const prevBtn = jQuery(".block-social-media #prevBtn");
const nextBtn = jQuery(".block-social-media #nextBtn");
const casinoName = jQuery(".block-social-media-post-modal .casino-name-span");
const postTitle = jQuery(".block-social-media-post-modal .post-title");
const description = jQuery(".block-social-media-post-modal .post-desc p");
const likes = jQuery(".block-social-media-post-modal .likes");

// Open the modal when clicking on a gallery image
jQuery(".gallery-item").click(function () {
  currentIndex = galleryItems.index(this);
  showImage(currentIndex);
  modal.addClass("d-flex");
});

// Close the modal when clicking the close button
jQuery(".close").click(function () {
  modal.removeClass("d-flex");
});

// Show the previous image
prevBtn.click(function () {
  currentIndex =
    currentIndex > 0 ? currentIndex - 2 : galleryItems.length - 2;
  showImage(currentIndex);
});

// Show the next image
nextBtn.click(function () {
  currentIndex =
    currentIndex < galleryItems.length - 2 ? currentIndex + 2 : 0;
  showImage(currentIndex);
});

// Display the clicked image in the modal
function showImage(index) {
  const src = galleryItems.eq(index).find(".gallery-item").attr("data-src");
  const casino = galleryItems.eq(index).find(".casino-name span").text();
  const title = galleryItems.eq(index).find(".post-title").text();
  const desc = galleryItems.eq(index).find("p").text();
  const likesCount = galleryItems.eq(index).find(".likes").text();
  popupImage.attr("src", src);
  popupImage.attr("alt", galleryItems.eq(index).attr("alt"));
  casinoName.text(casino);
  postTitle.text(title);
  description.text(desc);
  likes.text(likesCount);

  console.log(src);
}

// Hide modal on outside click
jQuery(window).click(function (event) {
  if (event.target === modal[0]) {
    modal.removeClass("d-flex");
  }
});

jQuery(".block-social-media-post-modal .likes-ctn").click(function (event) {
  jQuery(this).toggleClass("active");
});
